import {React, useState} from 'react';
import { Modal, Button } from 'antd';

const Logout = ({ onLogout }) => {
  const [uploadModalVisible, setUploadModalVisible] = useState(true);
  const handleOkLogout = () => {

    onLogout();
  };

  const handleCancel = () => {
    setUploadModalVisible(false)
    // Handle cancel action
  };

  return (
    <Modal
      title="Log out"
      visible={uploadModalVisible}
      onOk={handleOkLogout}
      // onCancel={handleCancel}
      footer={[
        // <Button key="cancel" onClick={handleCancel}>
        //   Cancel
        // </Button>,
        <Button key="logout" type="primary" onClick={handleOkLogout}>
          Log out
        </Button>,
      ]}
    >
      <p>Are you sure you want to log out?</p>
    </Modal>
  );
};

export default Logout;

