// WalletHistory.js
import React from 'react';
import { Table } from 'antd';

const WalletHistory = () => {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Transaction',
      dataIndex: 'transaction',
      key: 'transaction',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const data = [
    {
      key: '1',
      date: '2024-03-18',
      transaction: 'Deposit',
      amount: '$100.00',
      status: 'Completed',
    },
    {
      key: '2',
      date: '2024-03-17',
      transaction: 'Withdrawal',
      amount: '$50.00',
      status: 'Pending',
    },
    // Add more dummy data as needed
  ];

  return <Table columns={columns} dataSource={data} />;
};

export default WalletHistory;
