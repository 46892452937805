import React, { useState } from 'react';
import { Layout, Row, Col, Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const { Content } = Layout;

const LibraryFiles = ({initialData, updateParentFileList}) => {
    
    // const initialData = [
    //     {
    //       key: '1',
    //       name: 'Image 1',
    //       url: 'https://www.fnordware.com/superpng/pnggrad16rgb.png',
    //       width: 800,
    //       height: 600,
    //     },
    //     {
    //       key: '2',
    //       name: 'Image 2',
    //       url: 'https://www.fnordware.com/superpng/pngtest8rgba.png',
    //       width: 1024,
    //       height: 768,
    //     },
    //     {
    //       key: '3',
    //       name: 'Image 3',
    //       url: 'https://www.fnordware.com/superpng/pngtest8rgba.png',
    //       width: 800,
    //       height: 600,
    //     },
    //     {
    //       key: '4',
    //       name: 'Image 4',
    //       url: 'https://www.fnordware.com/superpng/pnggrad16rgb.png',
    //       width: 1024,
    //       height: 768,
    //     },
    //     // Add more image data objects as needed
    //   ];
      const [data, setData] = useState(() => {
        // Calculate initial height based on width to maintain aspect ratio
        console.log(initialData)
        const initialDataWithAspectRatio = initialData.map(item => ({
            ...item,
            originalWidth :item.width , // Default to 1 if width is missing
            originalHeight : item.height // Default to 1 if width is missing
        }));
        return initialDataWithAspectRatio;
    });
    
      const columns = [
        {
          title: 'Image',
          dataIndex: 'url',
          key: 'image',
          render: (url, record) => (
            <div style={{ maxWidth: 100, maxHeight: 100 }}>
              <img
                src={url}
                alt={record.name}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: 'auto',
                  height: 'auto',
                }}
              />
            </div>
          ),
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Width',
          dataIndex: 'width',
          key: 'width',
          render: (text, record) => (
            <div>
                {/* <Input
                style={{ borderRadius: '0px' }}
              
              placeholder={`Original Width ${record.originalWidth}`}
              readOnly
            /> */
            }
            <div style={{ borderRadius: '0px', color:'#bfbfbf' }}
              >{`Original Width ${record.originalWidth}`}</div>
            
            <Input
              value={record.width}
              placeholder={record.originalWidth}
              onChange={(e) => handleCellEdit(record.key, 'width', e.target.value)}
            />
            </div>
            
          ),
        },
        {
          title: 'Height',
          dataIndex: 'height',
          key: 'height',
          render: (text, record) => (
            <div>
                <div style={{ borderRadius: '0px', color:'#bfbfbf' }}
              >{`Original Height ${record.originalHeight}`}</div>
            
            <Input
            placeholder={`${record.originalHeight}`}
              value={record.height}
              onChange={(e) => handleCellEdit(record.key, 'height', e.target.value)}
            />
            </div>
          ),
        },
      ];
    
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(null);

  
  const handleSearch = (value) => {
    const filtered = data.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
    );
    setSearchText(value);
    setFilteredData(filtered);
  };

  const handleReset = () => {
    setSearchText('');
    setFilteredData(null);
  };

const handleCellEdit = (key, dataIndex, value) => {
    // Ensure the value is a valid positive integer
    const newValue = parseInt(value);
   // Ensure the value is at least 1
    const item = data.find(item => item.key === key);
    let newHeight, newWidth;
    let newData
    if (!isNaN(newValue) && newValue > 0) {
    // If width is being edited, adjust height to maintain the ratio
    if (dataIndex === 'width') {
        newWidth = newValue; 
        // Adjust height to maintain aspect ratio
        newHeight =  (newWidth/item.originalWidth * item.originalHeight).toFixed(2);
    }
    // If height is being edited, adjust width to maintain the ratio
    else if (dataIndex === 'height') {
        // Adjust width to maintain aspect ratio
        newHeight =  newValue;
        newWidth = (newHeight/item.originalHeight * item.originalWidth).toFixed(2);
    }
    newData = data.map(item => {
        if (item.key === key) {
            return { ...item, width: newWidth, height: newHeight };
        }
        return item;
    });

    }else{
        newData = data.map(item => {
            if (item.key === key) {
                return { ...item, width: null, height: null };
            }
            return item;
        });
    
    }
    // Update the state with the new data
    
    setData(newData);
    console.log(`Editing ${dataIndex} of ${key} to ${newWidth}`);
};



  return (
    <Content
      className="site-layout-background"
      style={{
        padding: 24,
        minHeight: 360,
        backgroundColor: '#f0f2f5', // Ant Design default background color
      }}
    >
      <Row justify="center" gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col xs={24} style={{ maxWidth: '75%' }}>
          <Input
            placeholder="Search by name"
            allowClear
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: '75%', height: 50 }} // Adjust width and height
          />
        </Col>
      </Row>
      <Row justify="center" gutter={[16, 16]}>
        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={filteredData || data}
            pagination={{ pageSize: 7 }}
            bordered
            size="middle"
            scroll={{ y: 600 }}
            rowClassName="editable-row"
            onCell={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  // Handle cell click event if needed
                },
              };
            }}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default LibraryFiles;
