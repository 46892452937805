import React from 'react';
import { Layout, Row, Col, Table, Button } from 'antd';

const { Content } = Layout;

const Downloads = () => {
  const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      files: ['john_brown_1.pdf', 'john_brown_2.pdf']
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
      files: ['jim_green_1.pdf']
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
      files: ['joe_black_1.pdf', 'joe_black_2.pdf']
    },
    {
      key: '4',
      name: 'Jim Red',
      age: 32,
      address: 'London No. 2 Lake Park',
      files: ['jim_red_1.pdf']
    },
    {
      key: '5',
      name: 'Jack White',
      age: 32,
      address: 'Paris No. 3 Lake Park',
      files: ['jack_white_1.pdf', 'jack_white_2.pdf', 'jack_white_3.pdf']
    },
  ];

  const handleDownload = (filename) => {
    // Add your logic to handle the download action here
    console.log(`Downloading ${filename}`);
  };

  const renderDownloadButtons = (files) => {
    return files.map((file, index) => (
      <Button
        key={index}
        type="primary"
        style={{ marginRight: 8 }}
        onClick={() => handleDownload(file)}
      >
        Download {index + 1}
      </Button>
    ));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderDownloadButtons(record.files),
    },
  ];

  return (
    <Content
      className="site-layout-background"
      style={{
        padding: 24,
        minHeight: 360,
        backgroundColor: '#f0f2f5', // Ant Design default background color
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Table columns={columns} dataSource={data} />
        </Col>
      </Row>
    </Content>
  );
};

export default Downloads;
