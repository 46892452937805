// config.js
const config = {
    apiUrl: 'http://127.0.0.1:5000', // Your API URL
    apiKey: 'your-api-key',           // Your API key
    debugMode: true,
    // BASE_URL:'http://127.0.0.1:4000'
    BASE_URL:'https://poetic-barnacle-finally.ngrok-free.app'                   // Debug mode flag
};

export default config;
