import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

const { Header } = Layout;

const AppHeader = () => {
  return (
    <Header className="site-layout-background" style={{ padding: '0 16px', background:'white' }}>
      <div style={{ float: 'right' }}>
        <Link to="/logout" style={{ color: 'black', marginRight: '16px' }}>
          <LogoutOutlined /> Logout
        </Link>
      </div>
    </Header>
  );
};

export default AppHeader;
