import React from 'react';
import { Layout, Tabs } from 'antd';
import { WalletOutlined, FileTextOutlined, CrownOutlined} from '@ant-design/icons';
import WalletHistory from './walletHistoryComponent.js'; // Import your components
import Invoices from './invoicesComponent.js';
import CurrentPlan from './currentPlanComponent.js';

const { Content } = Layout;
const { TabPane } = Tabs;

const Billing = () => {
    
    
  return (
    <Tabs defaultActiveKey="1" type="card"
    size="medium"
    >
      <TabPane
        tab={
          <span >
            <WalletOutlined style={{ marginRight: 8 }} />
            Wallet History
          </span>
        }
        key="1"
      >
        <WalletHistory />
      </TabPane>
      <TabPane
        tab={
          <span>
            <FileTextOutlined style={{ marginRight: 8 }} />
             Invoices
          </span>
        }
        key="2"
      >
        <Invoices />
      </TabPane>
      <TabPane
        tab={
          <span>
            <CrownOutlined style={{ marginRight: 8 }} />
            Current Plan
          </span>
        }
        key="3"
      >
        <CurrentPlan />
      </TabPane>
    </Tabs>
  );
};

export default Billing;
