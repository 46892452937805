import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { 
  //DashboardOutlined,
 // CloudOutlined,
  QuestionCircleOutlined,
  DownloadOutlined,
  SettingOutlined,
 // UploadOutlined,
  CloudUploadOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PictureOutlined,
  FileOutlined,
  EngineOutlined,
  DollarOutlined,
 } from '@ant-design/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = ({ collapsed, onCollapse }) => {
  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
     <div className="logo" style={{ height: '32px', margin: '16px' }}>
        {!collapsed ? <img src="/logo192.png" alt="Logo" style={{ width: '100%' }} /> :<img src="/favicon.ico" alt="Logo" style={{ width: '100%' }}/>}
      </div>
        {/* <SubMenu key="sub1" icon={<PictureOutlined />} title="Library">
          <Menu.Item key="1">
            <Link to="/library/upload">Upload</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/library/files">Files</Link>
          </Menu.Item>
        </SubMenu> */}
        {/* <SubMenu key="sub1" icon={<CloudUploadOutlined />} title="ODPOPS">
          <Menu.Item key="3">
            <Link to="/odpops/sheet">Sheet</Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/odpops/queue">Queue</Link>
          </Menu.Item>
        </SubMenu> */}
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
        <Menu.Item key="4" icon={<CloudUploadOutlined />}>
          <Link to="/odpops/sheet">ODPOPS</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<DownloadOutlined />}>
          <Link to="/odpops/queue">Downloads</Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<DollarOutlined />}>
          <Link to="/billing">Billing</Link>
        </Menu.Item>
        {/* <Menu.Item key="6" icon={<SettingOutlined />}>
          <Link to="/settings">Settings</Link>
        </Menu.Item> */}
        <Menu.Item key="7" icon={<QuestionCircleOutlined />}>
          <Link to="/help">Help</Link>
        </Menu.Item>
      </Menu>
      {/* <div className="collapse-icon" style={{ textAlign: 'center', marginTop: 'auto' }}>
        {collapsed ? />}
        // {collapsed ? <MenuUnfoldOutlined onClick={() => onCollapse(false)} /> : <MenuFoldOutlined onClick={() => onCollapse(true)} />}
      </div> */}
    </Sider>
  );
};

export default Sidebar;
