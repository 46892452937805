// CurrentPlan.js
import React from 'react';
import { Row, Col, Card } from 'antd';

const CurrentPlan = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Card title="Basic Plan" style={{ textAlign: 'center' }}>
          <p>Features:</p>
          <ul>
            <li>Feature 1</li>
            <li>Feature 2</li>
            <li>Feature 3</li>
          </ul>
          <p>Price: $10/month</p>
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Premium Plan" style={{ textAlign: 'center' }}>
          <p>Features:</p>
          <ul>
            <li>Feature 1</li>
            <li>Feature 2</li>
            <li>Feature 3</li>
          </ul>
          <p>Price: $20/month</p>
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Ultra Premium Plan" style={{ textAlign: 'center' }}>
          <p>Features:</p>
          <ul>
            <li>Feature 1</li>
            <li>Feature 2</li>
            <li>Feature 3</li>
          </ul>
          <p>Price: $30/month</p>
        </Card>
      </Col>
    </Row>
  );
};

export default CurrentPlan;
