import React, { useState , useEffect } from 'react';
import { Layout, Row, Col, Table, Button, Modal , message, Spin} from 'antd';
import { CloudDownloadOutlined, EyeOutlined, RedoOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from './../config';

const { Content } = Layout;


const ODPOPSQueue = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [data, setData] = useState([])


  const handleRetry = (filename) =>{
    setLoading(true)
    // 
    fetch(`${config.BASE_URL}/v1/odpops/retry${filename}`,  {   headers: {
      'ngrok-skip-browser-warning': '1231',
      'bypass-tunnel-reminder': '1234'
    }})
    .then(async (response) => {
      if(response.status == 201 ||response.status == 200){
        // showOutput(response.data.fileName)
        message.info('File is Processed Success!!!!');
        await reloadContent()
        setLoading(false)
    }
    })
    .catch((error) => {
       message.error("Error")
       setLoading(false)
    });
  }
  
  const reloadContent  = async ()=>{
    return axios.get(config.BASE_URL+ '/v1/odpops/upload',{ headers: {
      'ngrok-skip-browser-warning': '1231',
      'bypass-tunnel-reminder': '1234'
    } })
    .then(response => {
        // Set table data and mark loading as false
        console.log(response.data)
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        message.error('Failed to fetch table data');
        console.error('Error fetching table data:', error);
        setLoading(false); // Set loading to false even on error
      });
  }

useEffect(() => {
    // Fetch table data when the component mounts
    // axios.get('http://127.0.0.1:4000/v1/odpops/upload',{ headers: {
    //   'ngrok-skip-browser-warning': '1231'
    // } })
    axios.get(config.BASE_URL+ '/v1/odpops/upload',{ headers: {
      'ngrok-skip-browser-warning': '1231',
      'bypass-tunnel-reminder': '1234'
    } })
    .then(response => {
        // Set table data and mark loading as false
        console.log(response.data)
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        message.error('Failed to fetch table data');
        console.error('Error fetching table data:', error);
        setLoading(false); // Set loading to false even on error
      });
  }, []); // Empty dependency array to ensure this effect runs only once

  const showModal = (files) => {
    setModalData(files.map((file, index) => ({
      key: index,
      fileName: file,
      dimension: 'Mock Dimension',
      uploadDate: 'Mock Date'
    })));
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };
  const handleView = (filename) => {
    const pdfUrl = `${config.BASE_URL}/v1/odpops${filename}`;
    // const pdfUrl = `http://127.0.0.1:4000/v1/odpops${filename}`;
    window.open(pdfUrl, '_blank');
};
const handleDownload = (filename) => {
  const pdfUrl = `${config.BASE_URL}/v1/odpops/download${filename}`;
  // const pdfUrl = `http://127.0.0.1:4000/v1/odpops${filename}`;
  window.open(pdfUrl, '_blank');
};


  const renderDownloadButtons = (files, isVisible) => {
    return (
      <div>
      <div>
        {files.map((file, index) => (
          <Button
            key={index}
            type="primary"
            style={{ marginRight: 8 }}
            disabled={!isVisible}
            onClick={() => handleView(file)}
            icon={<EyeOutlined />}
          >
             
          </Button>
        ))}
      {files.map((file, index) => (
        <Button
          key={index}
          type="primary"
          style={{ marginRight: 8 }}
          disabled={!isVisible}
          onClick={() => handleDownload(file)}
          icon={<CloudDownloadOutlined />}
        >
           
        </Button>
      ))}
      {files.map((file, index) => (
        <Button
          key={index}
          type="primary"
          style={{ marginRight: 8 }}
          disabled={isVisible}
          onClick={() => handleRetry(file)}
          icon={<RedoOutlined />}
        >
           
        </Button>
      ))}
    </div>
    </div>

    );
  };

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: 'Uploaded On',
      dataIndex: 'uploadedOn',
      key: 'uploadedOn',
    },
    {
      title: 'Total Images',
      dataIndex: 'imageCount',
      key: 'imageCount',
    },
    // {
    //   title: 'View Files',
    //   key: 'viewFiles',
    //   render: (text, record) => (
    //     <Button icon={<EyeOutlined />} onClick={() => showModal(record.files)}></Button>
    //   ),
    // },
    {
      title:'Height',
      dataIndex: 'pdfHeight',
      key: 'pdfHeight'

    },
    {
      title: 'Output',
      key: 'action',
      render: (text, record) => {
        return renderDownloadButtons(record.files, record.isVisible)},
    },
  ];

  const modalColumns = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: 'Dimension',
      dataIndex: 'dimension',
      key: 'dimension',
    },
    {
      title: 'Upload Date',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
    },
  ];

  return (
    <Content
      className="site-layout-background"
      style={{
        padding: 24,
        minHeight: 360,
        backgroundColor: '#f0f2f5',
      }}
    >
      {loading ? ( // Display spinner while loading
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Table columns={columns} dataSource={data} />
          </Col>
        </Row>
      )}
      <Modal
        title="Output PDF"
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <div className="App">
                <embed src={`${config.BASE_URL}/v1/odpops${pdfData}`} type="application/pdf" width="100%" height="600" />
            </div>
        {/* <Table columns={modalColumns} dataSource={modalData} /> */}
      </Modal>
    </Content>
  );
};

export default ODPOPSQueue;
