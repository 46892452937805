import React, { useState, useEffect } from 'react';
import { Upload, Table, Button, message, Modal, Spin, Layout, Row, Col, Input, Form, Menu, Dropdown } from 'antd';

import { UploadOutlined, DeleteOutlined, CloudUploadOutlined, PlusOutlined} from '@ant-design/icons';
import axios from 'axios';
import PdfViewer from './pdfViewerComponent';
import LibraryFiles from './libraryFiles';
import { SearchOutlined, FilterOutlined, CheckOutlined, FileOutlined, SaveOutlined, CopyOutlined, SelectOutlined,CheckCircleOutlined,RotateRightOutlined} from '@ant-design/icons';

import { getImageSize } from 'react-image-size';
import styles from './odpopsSheet.module.css'
import config from './../config';


const { Content } = Layout;
const { Item } = Form;
const LibraryUpload = () => {
    const [fileList, setFileList] = useState([]);
    const [fileName, setFileName] = useState('');
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [pdfData, setPdfData] = useState(null);
    const [pdfModalVisible, setPdfModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState(fileList);
    const [paddingInput, setPaddingInput] = useState('0.4');
    const [showPending, setShowPending] = useState(false);
    const [filterType, setFilterType] = useState('all'); // 'all', 'valid', 'invalid'
    const [selectModalVisible, setSelectModalVisible] = useState(false);
    const [MIN_DPI,setMinDPI] = useState(300);
    const [pdfWidth, setPdfWidth] = useState(23);
    const [selectedImages, setSelectedImages] = useState([]);
    
    useEffect(() => {
        if (searchText) {
            const filtered = fileList.filter(item =>
                item.name.toLowerCase().includes(searchText.toLowerCase())
            );
            setFilteredData(filtered);
        }else{
            setFilteredData(fileList);
        }
        // Initialize filteredData with fileList
    }, [fileList, searchText])

    const handleUploadToServer = () => {
        if(selectedImages.length < 1){
            setUploadModalVisible(false)
        }else{
            setUploadModalVisible(false); // Close the modal
        setLoading(true)
        const formData = new FormData();
        formData.append('fileName', fileName)
        formData.append('paddingWidth', paddingInput)
        formData.append('DPI', MIN_DPI)
        formData.append('pdfWidth', pdfWidth)
        selectedImages.forEach((file) => {
            formData.append('files', file.copy? '' : file.originFileObj);
            formData.append('widthInches',file.width)
            formData.append('heightInches',file.height)
            formData.append('name', file.name)
            formData.append('originName',file.originFileObj.name )
            formData.append('dpiWidth',file.dpiWidth)
            formData.append('dpiHeight', file.dpiHeight)
            formData.append('orderId', file.orderId)
        });

        // axios.post('http://127.0.0.1:4000/v1/odpops/upload', formData, { responseType: 'arraybuffer' })
        //   .then((response) => {
        //     message.success('Files uploaded successfully');
        //     setFileList([]); // Clear the file list after successful upload
        //     setFileName('');
        //     setPdfData(new Blob([response.data]));
        //   })
        // axios.post('http://127.0.0.1:4000/v1/odpops/upload', formData)
        axios.post( config.BASE_URL + '/v1/odpops/upload', formData, {
        // axios.post('http://127.0.0.1:4000/v1/odpops/upload', formData, {
            headers: {
                'ngrok-skip-browser-warning': '1231',
                'bypass-tunnel-reminder': '1234'
              }
        })
        .then((response) => {
                setFileList([]); // Clear the file list after successful upload
                setFileName('');
                setSelectedImages([]);
                // handleClearAllFiles();
                //setPdfData(response.data);
                setLoading(false)
                console.log(response)
                setShowPending(false)
                if(response.status == 201 ||response.status == 200){
                    // showOutput(response.data.fileName)
                    message.info('File is Processed Success!!!!');
                }
                   
                
                //setPdfModalVisible(true)
            })
            .catch((error) => {
               
                if (error.code === 'ECONNABORTED' || error.code === 'ECONNRESET' ) {
                    message.info('Please wait while we are processing your files.');
                    message.error('Request timeout occurred, please try again later.');
                } else {
                    message.error('Failed to upload files');
                    console.error('Error uploading files:', error);
                }
                setLoading(false)
                setShowPending(true)

            });

        }
        
    };

    const handleUploadToServerV0 = () => {
        if(selectedImages.length < 1){
            setUploadModalVisible(false)
        }else{
        setUploadModalVisible(false); // Close the modal
        setLoading(true)
        const formData = new FormData();
        formData.append('fileName', fileName)
        formData.append('paddingWidth', paddingInput)
        formData.append('DPI', MIN_DPI)
        formData.append('pdfWidth', pdfWidth)
        selectedImages.forEach((file) => {
            formData.append('files', file.copy? '' : file.originFileObj);
            formData.append('widthInches',file.width)
            formData.append('heightInches',file.height)
            formData.append('name', file.name)
            formData.append('originName',file.originFileObj.name )
            formData.append('dpiWidth',file.dpiWidth)
            formData.append('dpiHeight', file.dpiHeight)
            formData.append('orderId', file.orderId)
            formData.append('rotateFlag', file.rotateFlag)
        });

        // axios.post('http://127.0.0.1:4000/v1/odpops/upload', formData, { responseType: 'arraybuffer' })
        //   .then((response) => {
        //     message.success('Files uploaded successfully');
        //     setFileList([]); // Clear the file list after successful upload
        //     setFileName('');
        //     setPdfData(new Blob([response.data]));
        //   })
        // axios.post('http://127.0.0.1:4000/v1/odpops/upload', formData)
        axios.post( config.BASE_URL + '/v1/odpops/upload', formData, {
        // axios.post('http://127.0.0.1:4000/v1/odpops/upload', formData, {
            headers: {
                'ngrok-skip-browser-warning': '1231',
                'bypass-tunnel-reminder': '1234'
              }
        })
        .then((response) => {
                setFileList([]); // Clear the file list after successful upload
                setFileName('');
                setSelectedImages([]);
                // handleClearAllFiles();
                //setPdfData(response.data);
                setLoading(false)
                console.log(response)
                if(response.status == 201 ||response.status == 200){
                    // showOutput(response.data.fileName)
                    message.info('File is Processed Success!!!!');
                }
                   
                
                //setPdfModalVisible(true)
            })
            .catch((error) => {
               
                if (error.code === 'ECONNABORTED' || error.code === 'ECONNRESET' ) {
                    message.info('Please wait while we are processing your files.');
                    message.error('Request timeout occurred, please try again later.');
                } else {
                    message.error('Failed to upload files');
                    console.error('Error uploading files:', error);
                }
                setLoading(false)
                setShowPending(true)

            });

        }
        
    };
    // Function to handle selecting images for server upload
    const handleSelectImageForUpload = (record) => {
        const index = selectedImages.findIndex(item => item.key === record.key);
        console.log(index, record.key)
        if(!record.valid){
            message.error("Can't add " +record.name+ " with invalid size.")
            return
        }
        if (index >= 0) {
            const updatedImages = [...selectedImages];
            updatedImages.splice(index, 1);
            setSelectedImages(updatedImages);
            
        } else {
            console.log(record)
            setSelectedImages([...selectedImages, record]);
            console.log(selectedImages)
        }
       
    };

    const handleRotateImage = (record) =>{
        // const index = selectedImages.findIndex(item => item.key === record.key);
        // const item = fileList.find(item => item.key === record.key);
            let newRotateFlag = !record.rotateFlag
            let newData = fileList.map(item => {
                if (item.key === record.key) {
                    item.rotateFlag = newRotateFlag;
                    let tempW = item.width
                    item.width = item.height;
                    item.height = tempW;
                    console.log({item})
                    item.valid = isRowValid(item)
                    return {...item};
                }
                return item;
            });
           
            setFileList(newData);
            // if(filteredData.length > 0){

            // }
    }
    // const handleCopyImage = (record) => {
    //     const copiedImage = { ...record };
    //     copiedImage.key = Date.now(); // Generate a new key for the copied image
    //     setFileList([...fileList, copiedImage]);
    // };
    const handleCopyImage = (record) => {
        const copiedImage = { ...record };
        const newKey = fileList.length; // Assuming you are using UUIDs for unique keys
        copiedImage.key = newKey;
        copiedImage.name = `Copy_${newKey}_${record.originFileObj.name}`; // Change the name to indicate it's a copy
        copiedImage.orderId = newKey+1; // Assign a new order ID
        copiedImage.copy = true;
        // copiedImage.originFileObj.name = `Copy_${newKey}_${record.originFileObj.name}`;
    
        // Insert the copied image just below the original image
        const index = fileList.findIndex((item) => item.key === record.key);
        const newDataSource = [...fileList];
        newDataSource.splice(index + 1, 0, copiedImage);
    
        setFileList(newDataSource);
    };
    const isRowValid = (record) => {
        // Example logic: If DPI value is less than 72, mark row as red
        if (record.dpiHeight == Infinity || record.dpiWidth == Infinity || record.width >=22.6) {
            return false;
        }
        // Otherwise, mark row as green
        return true;
    };

    const showOutput = (filename) => {
        const pdfUrl = `${config.BASE_URL}/v1/odpops/${fileName}/${filename}`;
        // const pdfUrl = `http://127.0.0.1:4000/v1/odpops/${fileName}/${filename}`;
        window.open(pdfUrl, '_blank');
    };

    const generateFileName = () => {
        //const currentDate = new Date();
        //const formattedDate = currentDate.toLocaleString().replace(/[/:]/g, '-');
        setFileName(`File_${Date.now()}`);
    };

    const handleModalClose = () => {
        setPdfData(null)
        setPdfModalVisible(false);
    };

    const handlePaddingInput = (event) => {
        const { value } = event.target;
        const onlyNums = value.replace(/[^0-9.]/g, '');
        setPaddingInput(onlyNums);
    }

    const handleDeleteRow = (file) => {
        console.log(file)
        const newFileList = fileList.filter((item) => item.key !== file.key);
        const newSelectedList = selectedImages.filter((item) => item.key !== file.key)
        setSelectedImages(newSelectedList)
        setFileList(newFileList);
    };

    const handleClearAllFiles = () => {
        Modal.confirm({
            title: 'Confirm Clear',
            content: 'Are you sure you want to clear all files?',
            onOk() {
                setFileList([]);
                setFileName('');
                setPdfData(null);
                setSelectedImages([]);
            },
        });
    };
    const columns = [
        {
            title: 'Del',
            dataIndex: 'actions',
            key: 'actions',
            width: 50, // Set the width to fit the icon
            align: 'center', // Align the content to center
            render: (text, record) => (
               
                <span>
                <Button
                    type="danger"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteRow(record)}
                />
               
               
            </span>
            ),
        },
        {
            title: 'Image',
            dataIndex: 'url',
            key: 'image',
            width: 120,
            align: 'center',
            render: (url, record) => (
                <div style={{ maxWidth: 100, maxHeight: 100 }}>
                    <img
                        src={url}
                        alt={record.name}
                        style={{
                            maxWidth: '100px',
                            maxHeight: '100px',
                            width: 'auto',
                            height: 'auto',
                            transform: record.rotateFlag ? 'rotate(90deg)' : 'none',
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (text, record) => (
                <div style={{ textAlign: 'center' }}>{record.name}</div>
            ),
        },
        {
            title: 'FIle Size(Kb)',
            dataIndex: 'imgSize',
            key: 'imgSize',
            align: 'center',
            width:100,
            render: (text, record) => (
                <Input
                    placeholder={`size in Kb`}
                    value={record.imgSize + ' Kb'} 
                    // onChange={(e) =>
                    //     handleCellEditOrderId(record.key, 'orderId', e.target.value)
                    // }
                    disabled={true}
                    style={{
                        width: '80px',
                        textAlign: 'center',
                        borderRadius: '4px',
                    }}
                />
            ),
        },
        {
            title: 'Width x Height',
            dataIndex: 'width',
            key: 'width',
            align: 'center',
            // '100px 80px 10px 80px'
            render: (text, record) => (
                <div style={{ textAlign: 'center' }}>
                   
              
                     <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gridColumnGap: '4px', alignItems: 'center' }}>
                         <span>{`Pixel(original)`}</span>
                         <Input
                             value={record.originalWidth}
                             style={{ width: 'auto', textAlign: 'center' }}
                             disabled={true}
                         />
                         <span>x</span>
                         <Input
                             value={record.originalHeight}
                             style={{ width: 'auto',textAlign: 'center' }}
                             disabled={true}
                             className={record.valid ? '': 'invalid-input'}
                         />
                         <span>{`Inches(input)`}</span>
                         <Input
                             placeholder={record.width}
                             value={record.width}
                             onChange={(e) => handleCellEditDimension(record.key, 'width', e.target.value)}
                             style={{ width: 'auto',textAlign: 'center' }}
                             className={record.valid ? styles['valid-input'] : styles['invalid-input']}
                             disabled={selectedImages.some((item) => item.key === record.key)}
                         />
                         <span>x</span>
                         <Input
                             placeholder={record.height}
                             value={record.height}
                             onChange={(e) => handleCellEditDimension(record.key, 'height', e.target.value)}
                             className={record.valid ? styles['valid-input'] : styles['invalid-input']}
                             style={{ width: 'auto',textAlign: 'center' }}
                             disabled={selectedImages.some((item) => item.key === record.key)}
                         />
                         
                         
                 </div>
    
                </div>
               

            ),
            sorter: (a, b) => a.width - b.width,
        },
        {
            title: 'Action',
            key: 'actions',
            width:150,
            align: 'center',
            render: (text, record) => (
                <span>
                <Button
                type="text"
                icon={<CopyOutlined />}
                onClick={() => handleCopyImage(record)}
                style={{ color: '#1890ff', marginRight: '8px' }}
            />
            
            <Button
                type="text"
                icon={<CheckCircleOutlined />}
                onClick={() => handleSelectImageForUpload(record)}
                style={{
                    color: selectedImages.some((item) => item.key === record.key) ? '#52c41a':'#1890ff',
                }}
            />

            <Button
                    type="text"
                    icon={<RotateRightOutlined />}
                    onClick={() => handleRotateImage(record)}
                    style={{
                        color: selectedImages.some((item) => item.key === record.key) ? '#52c41a':'#1890ff',
                    }}
                />
                </span>
            ),
        },
        
    ];
    
    const FilterMenu = (
        <Menu onClick={(e) => handleFilter(e.key)}>
        <Menu.Item key="all" icon={filterType === 'all' ? <CheckOutlined /> : null}>all</Menu.Item>
        <Menu.Item key="valid" icon={filterType === 'valid' ? <CheckOutlined /> : null}>valid</Menu.Item>
        <Menu.Item key="invalid" icon={filterType === 'invalid' ? <CheckOutlined /> : null}>warnings</Menu.Item>
    </Menu>
    );

    const handleSearch = (value) => {
        const filtered = fileList.filter(
            (item) =>
                item.name.toLowerCase().includes(value.toLowerCase())
        );
        setSearchText(value);
        setFilteredData(filtered);
    };
    const handleFilter = (type) => {
        setFilterType(type);
        switch (type) {
            case 'valid':
                setFilteredData(fileList.filter(item => item.valid));
                break;
            case 'invalid':
                setFilteredData(fileList.filter(item => !item.valid));
                break;
            default:
                setFilteredData(fileList);
                break;
        }
    };

    const handleReset = () => {
        setSearchText('');
        setFilteredData(null);
    };
    const handleCellEditOrderId = (key, dataIndex, value) => {
        
        if(filteredData.length > 0){
            const item = fileList.find(item => item.key === key);
            let orderId= value
            let newData = fileList.map(item => {
                if (item.key === key) {
                    return { ...item, orderId };
                }
                return item;
            });
            const filtered = fileList.filter(
                (item) =>
                    item.name.toLowerCase().includes(searchText.toLowerCase())
            );
            setFileList(newData);
            setFilteredData(filtered);
        }else{
            const item = fileList.find(item => item.key === key);
            let orderId= value
            let newData = fileList.map(item => {
                if (item.key === key) {
                    return { ...item, orderId };
                }
                return item;
            });
            setFileList(newData);
        }
        
    }
    const handleCellEditDimension = (key, dataIndex, value) => {
        // Ensure the value is a valid positive integer
        const newValue = value;
        console.log({ newValue, dataIndex })
        // Ensure the value is at least 1
        const item = fileList.find(item => item.key === key);
        let newHeight = item.height, newWidth = item.width;
        let newData, dpiHeight = item.dpiHeight, dpiWidth =item.dpiWidth;
        if (newValue.match(/^\d{0,2}(\.\d{0,2})?$/)) {
            // If width is being edited, adjust height to maintain the ratio
            if (dataIndex === 'width') {
                newWidth = newValue;
                // Adjust height to maintain aspect ratio
                newHeight = (newWidth / item.originalWidth * item.originalHeight).toFixed(2);
                if(newHeight==0){
                    newHeight=null
                }
                
            }
            // If height is being edited, adjust width to maintain the ratio
            else if (dataIndex === 'height') {
                // Adjust width to maintain aspect ratio
                newHeight = newValue;
                newWidth = (newHeight / item.originalHeight * item.originalWidth).toFixed(2)  ;
                if(newWidth==0){
                    newWidth=null
                }
            }
            dpiHeight = (item.originalHeight/newHeight).toFixed(2)
            dpiWidth = (item.originalWidth/newWidth).toFixed(2)

        }
        
        newData = fileList.map(item => {
            if (item.key === key) {
                return { ...item, width: newWidth, height: newHeight ,dpiHeight,dpiWidth, valid : isRowValid({dpiHeight, dpiWidth, width: newWidth, height: newHeight}) };
            }
            return item;
        });
        //  else {
        //     newData = fileList.map(item => {
        //         if (item.key === key) {
        //             return { ...item, width: width, height: height, dpiWidth:null, dpiHeight:null, valid : isRowValid({dpiHeight, dpiWidth}) };
        //         }
        //         return item;
        //     });

        // }
        // Update the state with the new data
        setFileList(newData);
        if(filteredData.length>0){
            handleSearch(searchText)
        }
        console.log(`Editing ${dataIndex} of ${key} to ${newWidth}`);
    };

    const formatFileList = async (fileList) => {
        console.log("fileList:- ", fileList)

        let dataSource = []
        let i =0;
        for (const index in fileList) {
            i++;
            // console.log(file)
            let dimension = await getImageSize(URL.createObjectURL(fileList[index].originFileObj))
            console.log(dimension)
            dataSource = dataSource.concat(
                { ...fileList[index], 
                    key: index, 
                    url: URL.createObjectURL(fileList[index].originFileObj), 
                    originalHeight: dimension.height, 
                    originalWidth: dimension.width, 
                    dpiHeight: MIN_DPI, dpiWidth: MIN_DPI, 
                    width: fileList[index].width || (dimension.width / MIN_DPI).toFixed(2), 
                    height: fileList[index].height || (dimension.height / MIN_DPI).toFixed(2), 
                    valid: isRowValid({ dpiWidth: MIN_DPI, dpiHeight: MIN_DPI, width: fileList[index].width || (dimension.width / MIN_DPI).toFixed(2), 
                    height: fileList[index].height || (dimension.height / MIN_DPI).toFixed(2) }), 
                    orderId: fileList[index].orderId || i,
                    rotateFlag: fileList[index].rotateFlag || false,
                    imgSize: Math.ceil(fileList[index].size/1024)
                })

            console.log(dataSource)
        }
        setFileList(dataSource)
    }

    const uploadProps = {
        multiple: true,
        showUploadList: false,
        fileList: fileList,
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false; // Prevent default upload behavior
        },
        onChange: (info) => {
            generateFileName();
            formatFileList(info.fileList);
            setSelectModalVisible(false)
            // setFileList(info.fileList);
        },
    };

    const uploadModalContent = (
        <div style={{ marginBottom: '20px' }}>
            <Upload.Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <UploadOutlined />
                </p>
                <p className="ant-upload-text">Click or drag files to this area to upload</p>
            </Upload.Dragger>
        </div>
    );

    return (

        <div>
            <div>
                <div>
                <Modal
                    title="Confirm Upload"
                    visible={selectModalVisible}
                    onOk={() => setSelectModalVisible(false)}
                    onCancel={() => setSelectModalVisible(false)}
                >
                    {uploadModalContent}
                </Modal>
                </div>
               
                

                {/* <div style={{  display: 'flex', alignItems: 'center', borderRadius: '0px' }}>
                    <Button type="dashed" style={{ borderRadius: '0px', background: '#1777ff' }} onClick={() => setSelectModalVisible(true)} disabled={loading}>
                        Add Images
                    </Button>
                    <Button type="dashed" style={{marginLeft: '10px', borderRadius: '0px', background: '#1ce81c' }} onClick={() => setUploadModalVisible(true)} disabled={!fileList.length}>
                        Generate Sheet
                    </Button>
                    <Button type="dashed" onClick={handleClearAllFiles} style={{ marginLeft: '10px', borderRadius: '0px', background: '#FFCCCC' }} disabled={!fileList.length}>
                        Clear All Images
                    </Button>
                    
                </div> */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '25px', padding: '10px 20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>DPI:</span>
                        <Input
                            placeholder="DPI"
                            value={MIN_DPI}
                            readOnly
                            disabled={true}
                            onChange={(e) => setMinDPI(e.target.value)}
                            style={{ marginLeft: '10px', borderRadius: '25px' , width:'50px',textAlign: 'center'}}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginLeft: '5px' }}>Width:</span>
                        <Input
                            placeholder="width"
                            value={pdfWidth}
                            readOnly
                            disabled={true}
                            onChange={(e) => setMinDPI(e.target.value)}
                            style={{ marginLeft: '10px',borderRadius: '25px' , width:'50px',textAlign: 'center'}}
                        />
                    </div>
                  
                    <div style={{alignItems: 'center' , display: 'flex', justifyContent: 'center' }}>
                        
                                <Input
                                    placeholder="Search by name"
                                    allowClear
                                    prefix={<SearchOutlined />}
                                    value={searchText}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    style={{ width: '75%', height: 40, marginRight: 8 ,align: 'center',textAlign: 'center', borderRadius: '25px'}} // Adjust width and height
                                />
                                <Dropdown overlay={FilterMenu}>
                                    <Button icon={<FilterOutlined />} style={{ height: 40, borderRadius: '25px' }}>
                                        {filterType}
                                    </Button>
                                </Dropdown>
                        </div>

                        <div style={{ marginRight: '5px' }}>
                        <Button type="primary" icon={<PlusOutlined/>} style={{ borderRadius: '25px', background: '#1677ff'}} onClick={() => setSelectModalVisible(true)} disabled={loading}>
                            Add images
                        </Button>
                        {/* <Button type="primary" icon={<SaveOutlined/>} style={{ borderRadius: '4px', background: '#1677ff', marginLeft: '10px',}} onClick={() => setSelectModalVisible(true)} disabled={!fileList.length}>
                            Save draft
                        </Button> */}
                        
                        <Button type="primary"  icon={<DeleteOutlined/>} onClick={handleClearAllFiles} style={{ marginLeft: '10px', borderRadius: '25px', background: '#FFCCCC' }} disabled={!fileList.length}>
                            Clear all images
                        </Button>
                        
                        <Button type="primary" icon={<CloudUploadOutlined/>} style={{marginLeft: '10px', borderRadius: '25px', background: '#1ce81c' }} onClick={() => setUploadModalVisible(true)} disabled={!fileList.length}>
                            Generate sheet
                        </Button>
                    </div>
                    
                </div>
              


                <div>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            minHeight: 360,
                            backgroundColor: '#f0f2f5', // Ant Design default background color
                        }}
                    >
                       
                        <Row  justify="center" gutter={[16, 16]} style={{ marginBottom: 16 }}>
                        {fileList.length > 0 && (
                        <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center', fontFamily: 'Roboto, sans-serif' }}>
                            <div style={{ marginRight: '20px', padding: '6px', background: 'white', borderRadius: '0px', color: '#000' }}>File name
                                <input
                                    type="text"
                                    onChange={(e) => setFileName(e.target.value)}
                                    value={fileName}
                                    style={{ color: '#000', marginLeft: '5px',textAlign: 'center', borderRadius: '0px' }}
                                />
                            </div>
                            <div style={{ marginRight: '20px', padding: '6px', background: '#1777ff', borderRadius: '0px', color: '#fff' }}>Total Images
                                <input
                                    type="text"
                                    value={fileList.length}
                                    readOnly
                                    disabled={true}
                                    style={{ color: '#fff ', marginLeft: '5px', width: '50px',textAlign: 'center', borderRadius: '0px'  }}
                                />
                            </div>

                            <div style={{ marginRight: '20px', padding: '6px', background: '#1777ff', borderRadius: '0px', color: '#fff' }}>Selected images
                                <input
                                    type="text"
                                    value={selectedImages.length}
                                    readOnly
                                    disabled={true}
                                    style={{ color: '#fff', marginLeft: '5px', width: '50px',textAlign: 'center',borderRadius: '0px' }}
                                />
                            </div>
                            {/* <div style={{ marginRight: '20px', padding: '6px', background: '#1777ff', borderRadius: '4px', color: '#fff' }}>Valid images
                                <input
                                    type="text"
                                    value={fileList.filter(item => item.valid).length}
                                    readOnly
                                    disabled={true}
                                    style={{ color: '#fff', marginLeft: '5px', border: 'none', width: '50px',textAlign: 'center', borderRadius: '3px' }}
                                />
                            </div> */}
                            <div style={{ marginRight: '20px', padding: '6px', background: '#1777ff', borderRadius: '0px', color: 'white' }}>Warnings
                                <input
                                    type="text"
                                    value={fileList.filter(item => ! item.valid).length}
                                    readOnly
                                    disabled={true}
                                    style={{ color: 'white', marginLeft: '5px', width: '50px',textAlign: 'center', borderRadius: '0px' }}
                                />
                            </div>
                            {/* <div style={{ marginRight: '20px', padding: '6px', background: '#1777ff', borderRadius: '4px', color: '#fff' }}>Pdf width
                                <input
                                    type="text"
                                    value={pdfWidth}
                                    readOnly
                                    disabled={true}
                                    style={{ color: '#fff', marginLeft: '5px', border: 'none', width: '50px',textAlign: 'center', borderRadius: '3px' }}
                                />
                            </div> */}
                            <div style={{ marginRight: '20px', padding: '6px', background: 'white', borderRadius: '0px', color: '#000' }}>Padding
                                <input
                                    type="text"
                                    placeholder='inches'
                                    value={paddingInput}
                                    onChange={handlePaddingInput}
                                    style={{ color: '#000', marginLeft: '5px',  width: '50px' ,textAlign: 'center', borderRadius: '0px'}}
                                />
                            </div>
                        </div>
                        )}
                        </Row>
                        <Row justify="center" gutter={[16, 16]}>
                            <Col xs={24}>
                                {
                                    loading ? ( // Display spinner while loading
                                        <div style={{ textAlign: 'center' }}>
                                            <Spin size="large" />
                                        </div>
                                    ) : (

                                        <Table
                                            columns={columns}
                                            dataSource={filteredData || fileList}
                                            pagination={{ pageSize: 10, position: ['topRight', 'bottomRight'], // Pagination position
                                            showSizeChanger: true, // Show page size changer
                                            pageSizeOptions: ['10', '20', '30', '40']  }}
                                            bordered
                                            size="middle"
                                            scroll={{ y: "auto" }}
                                            rowClassName={record => record.valid ? styles['valid-row'] : styles['invalid-row']}
                                            onCell={(record, rowIndex) => {
                                                return {
                                                    onClick: (event) => {
                                                        // Handle cell click event if needed
                                                    },
                                                };
                                            }}
                                        />
                                    )}
                            </Col>
                        </Row>
                    </Content>


                </div>
            </div>
            <Modal
                title="Confirm Upload"
                visible={uploadModalVisible}
                onOk={handleUploadToServerV0}
                onCancel={() => setUploadModalVisible(false)}
            >
                {
                     selectedImages.length > 0 ?  <p>Are you sure you want to upload {selectedImages.length} files?</p> : <p>Please select some images to Generate Files</p>
                }
                
            </Modal>
        </div>
    );
};

export default LibraryUpload;
